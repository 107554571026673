import React, {useState} from "react"
import Layout from '../components/layout'
import HeroSection from '../components/main/heroSection'
import DetailSection from '../components/main/detailSection'
import ComplianceSection from '../components/main/complianceSection'
import OneStopForAllSection from '../components/main/oneStopForAllSection'
import IntegratedAppSection from '../components/main/integratedAppSection'
import Modal from '../components/shared/modal';
import "../styles/index.css";

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{  maxwidth:"100%", overflow: "hidden"}}>
      <Layout pageTitle="Delego" open={open} setOpen={setOpen}>
        <HeroSection open={open} setOpen={setOpen}/>
        <DetailSection />
        <ComplianceSection />
        <OneStopForAllSection />
        <IntegratedAppSection />
      </Layout>
      <Modal open={open} setOpen={setOpen} />
    </div>
  )
}

export default IndexPage
