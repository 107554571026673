// extracted by mini-css-extract-plugin
export var heroContainer = "main-module--heroContainer--1ZnMJ";
export var leftContainer = "main-module--leftContainer--aSv2a";
export var rightContainer = "main-module--rightContainer--11Ap9";
export var headline = "main-module--headline--viXaY";
export var subText = "main-module--subText--tPOoW";
export var actionButton = "main-module--actionButton--286rC";
export var image = "main-module--image--3bV6q";
export var headLineStyle = "main-module--headLineStyle--2wRD4";
export var greenColor = "main-module--greenColor--1wadA";
export var green = "main-module--green--2obRZ";
export var blue = "main-module--blue--SWvTB";
export var subTextStyle = "main-module--subTextStyle--1kmxV";
export var topPadding = "main-module--topPadding--1t9GC";
export var wrapper = "main-module--wrapper--2qUM6";
export var dynamicText = "main-module--dynamicText--33lEI";
export var slide = "main-module--slide--iu2LN";
export var detailContainerCard = "main-module--detailContainerCard--bNivI";
export var headingDetail = "main-module--headingDetail--1Z0-J";
export var borderBottom = "main-module--borderBottom--2Tv8Y";
export var messyImage = "main-module--messyImage--1NNnk";
export var cardContainer = "main-module--cardContainer--u6_4C";
export var cardWithLeftIconContainer = "main-module--cardWithLeftIconContainer--3gtOd";
export var cardRightContainer = "main-module--cardRightContainer--2rf8u";
export var headingStyle = "main-module--headingStyle--7BW-s";
export var textStyle = "main-module--textStyle--3NPvA";
export var imageIconLeftContainer = "main-module--imageIconLeftContainer--vzVZD";
export var imageIconRightContainer = "main-module--imageIconRightContainer--3rmpS";
export var fullContainer = "main-module--fullContainer--jvYmH";
export var smallIconContainer = "main-module--smallIconContainer--35oAt";
export var smallIconContainerRight = "main-module--smallIconContainerRight--3bl3O";
export var imageCorner = "main-module--imageCorner--3TS4j";
export var complianceContainer = "main-module--complianceContainer--_yqug";
export var iconContainer__compliance = "main-module--iconContainer__compliance--1wJyc";
export var textContainer__compliance = "main-module--textContainer__compliance--3HkPB";
export var heading__compliance = "main-module--heading__compliance--Kbuw4";
export var descriptionText__compliance = "main-module--descriptionText__compliance--14hXL";
export var icon__container = "main-module--icon__container--2XiD_";
export var underline__text = "main-module--underline__text--95DPj";
export var underline__heading = "main-module--underline__heading--YsPJ1";
export var imagesIconSize = "main-module--imagesIconSize--3BeBW";
export var container__onestop = "main-module--container__onestop--Igf8G";
export var heading__onestop = "main-module--heading__onestop--1siEV";
export var small__heading__onestop = "main-module--small__heading__onestop--3cOOI";
export var mainheading__onestop = "main-module--mainheading__onestop--1FINY";
export var content__onestop = "main-module--content__onestop--fMZ0d";
export var division__onestop = "main-module--division__onestop--QanJG";
export var grid__deciding_one = "main-module--grid__deciding_one--Wu6fA";
export var grid__deciding_two = "main-module--grid__deciding_two--bMA06";
export var grid__deciding_three = "main-module--grid__deciding_three--27FHj";
export var grid__deciding_four = "main-module--grid__deciding_four--3awxZ";
export var list__heading__onestop = "main-module--list__heading__onestop--2glvZ";
export var ul__onestop = "main-module--ul__onestop--KtkDW";
export var borderBottomWhite = "main-module--borderBottomWhite--3qwRT";
export var tickMarkImage = "main-module--tickMarkImage--j6vp3";
export var container__integratedapp = "main-module--container__integratedapp--3saCw";
export var heading__integratedapp = "main-module--heading__integratedapp--31UZs";
export var imagecontainer__integratedapp = "main-module--imagecontainer__integratedapp--2Z6Rl";
export var imageSize__companyLogo = "main-module--imageSize__companyLogo--lfw_K";
export var image__paperless = "main-module--image__paperless--2sJrm";
export var image__fullcontainer = "main-module--image__fullcontainer--1DtVH";