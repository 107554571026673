import * as React from 'react'
import { CardWithLeftIcon, CardFullSize, CardWithSmallIcon }from './card'
import {
  cardWithLeftIconContainer, 
  fullContainer,
  smallIconContainer, 
  smallIconContainerRight, 
  headingDetail,
  detailContainerCard,
  borderBottom,
  messyImage,
} from './main.module.css'
import { text } from '../common.module.css'
import paperless from '../../images/paperless.svg'
import rocket from '../../images/rocket.svg'
import yinyang from '../../images/ball-yinyang.svg'
import key from '../../images/key.svg'
import messy from '../../images/messy.svg'

const DetailSection = (props) => {
  return (
    <div>
      <div className={`${text} ${headingDetail}`}>
        <span className={borderBottom}>Get ahead of compliance </span>
      </div>
    <div className={detailContainerCard}> 
        <CardWithLeftIcon 
          heading="Paperless Management"
          textContent="E-signatures and a secure document hub makes coordination better for signatures from multiple signatories."
          imageSrc={paperless}
          extraClass={cardWithLeftIconContainer}
          />
          <img src={messy} className={messyImage} alt="a boy messing up the paperwork's"/>
        <CardFullSize 
          heading="Digital Companies"
          textContent="One place to view all your company’s important dates, deadlines, registrations and exemptions. Online tasks let you send, respond and track requests online from start to finish. No more scrolling through long emails and spreadsheets, scratching your head, wondering whether your chartered accountant and company secretary are on top of things."
          imageSrc={rocket}
          extraClass={fullContainer}  
          />
        <CardWithSmallIcon 
          heading="Secure Data Room and Fileshare"
          textContent="Data room securely stores company's documents secured with extensive permissions, collect documents from clients and share documents easily with your chartered accountant and company secretary."
          imageSrc={key}
          extraClass={smallIconContainer} 
          />
        <CardWithSmallIcon 
          heading="Automate manual Paper based Tasks"
          textContent="Simple workflows and standardized templates help you streamline your processes and gain efficiencies that scale. Spend more time doing what you love, and less time fetching, filing and finding documents."
          imageSrc={yinyang}
          extraClass={smallIconContainerRight} 
          />
    </div>
    </div>
  )
}

export default DetailSection