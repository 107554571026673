import * as React from 'react'
import { text } from '../common.module.css'
import RequestDemoButton from '../shared/requestDemoButton'
import computer from '../../images/hero-computer.svg'
import {
  heroContainer, 
  headline , 
  subText,
  leftContainer,
  actionButton,
  rightContainer,
  image, 
  headLineStyle,
  subTextStyle,
  topPadding,
  greenColor,
  dynamicText,
  wrapper,
} from './main.module.css'


const HeroSection = (props) => {
  const { open, setOpen } = props;
  return (
    <div className={heroContainer}>
      <div className={leftContainer}>
        <div className={`${text} ${headline} ${wrapper}`}>
          <div className={`${text} ${headLineStyle}`}>Where founders get their </div>
            <ul className={`${text} ${headLineStyle} ${greenColor} ${dynamicText}`}>
              <li><span>Secretarial</span></li>
              <li><span>Tax</span></li>
              <li><span>Accounting</span></li>
            </ul>
          <div className={`${text} ${headLineStyle}`}>compliances done safely.</div>
        </div>
        <div  className={`${text} ${subText}`}>
          <div className={`${text} ${subTextStyle}`}>
            Delego helps stakeholders to Track important deadlines, Manage compliances and Store regulatory information with significant reduction in your time and effort.
          </div>
          <div className={`${text} ${subTextStyle} ${topPadding}`}>
          Delego helps companies stay on top of Secretarial, Tax and Accounting paperwork.  
          </div>
        </div>
        <div className={actionButton}>
          <RequestDemoButton variant="outline" open={open} setOpen={setOpen}/>
        </div>
      </div>
      
     
      <div className={rightContainer}>
        <img className={image} alt="A guy with laptop" src={computer}></img>
      </div>
    </div>
  )
}

export default HeroSection