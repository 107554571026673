import * as React from 'react'
import {
  container__onestop,
  heading__onestop,
  small__heading__onestop,
  mainheading__onestop,
  content__onestop,
  division__onestop,
  list__heading__onestop,
  ul__onestop,
  borderBottomWhite,
  grid__deciding_one,
  grid__deciding_two,
  grid__deciding_three,
  grid__deciding_four,
  tickMarkImage,
} from './main.module.css'

import {text} from '../common.module.css'
import tick from '../../images/tick-mark.svg'

const OneStopForAllSection = (props) => {
  return (
    <div className={`${container__onestop} ${text}`}>
      <div className={heading__onestop}>
          <div className={small__heading__onestop}>One stop for all</div>
          <div className={mainheading__onestop}> <span className={borderBottomWhite}>A One Stop Shop For Compliance Management</span> </div>
      </div>
      <div className={content__onestop}>
        <div className={`${division__onestop} ${grid__deciding_one}`}>
          <div className={list__heading__onestop}>Secretarial</div>
          <ul className={ul__onestop}>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Real time Dashboard on compliance status</li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Updated monthly compliance calendars </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Email alerts to initiate compliance tasks </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Digitized and Standardised workflows </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Prefilled minutes, resolutions, MCA forms </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>E-sign resolutions and forms </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>FY wise repository of regulatory documents as per Companies Act 2013 </li>
          </ul>
        </div>
        <div className={`${division__onestop} ${grid__deciding_two}`}>
          <div className={list__heading__onestop}>Income Tax </div>
          <ul className={ul__onestop}>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> Fetch Tax Returns and ITR V </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Fetch Form 26AS </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> E-file income Tax Returns </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> Tax calculator APIs </li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> AY wise Repository of  ITRs, demand notices, Orders etc</li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Litigation Management system for faceless assessments</li>
            <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> Integration with IT website as e-filing intermediary</li>
          </ul>
        </div>
        <div className={`${division__onestop} ${grid__deciding_three}`}>
          <div className={list__heading__onestop}>GST</div>
          <ul className={ul__onestop}>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/> Verify Businesses and GSTIN</li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Track GST Returns </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Track and manage notices, reminders, refunds, SCNs etc </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Integration with GST portal through GSPs</li>
          </ul>
        </div>
        <div className={`${division__onestop} ${grid__deciding_four}`}>
          <div className={list__heading__onestop}>Accounting</div>
          <ul className={ul__onestop}>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Monthly Bookkeeping </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Monthly Management Report </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Payroll Support </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Unaudited financial Statements</li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Cloud repository of according records </li>
              <li><img src={tick} className={tickMarkImage} alt="tickmark icon"/>Integration with accounting softwares (Tally, QB, Xero, Zoho)</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OneStopForAllSection