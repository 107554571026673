import * as React from 'react'
import zoho from '../../images/zoho.svg'
import xero from '../../images/xero.svg'
import tally from '../../images/tally.svg'
import quickbooks from '../../images/quickbooks.svg'
import docusign from '../../images/docusign.svg'
import { container__integratedapp, heading__integratedapp, imagecontainer__integratedapp, imageSize__companyLogo} from './main.module.css'
import {text} from '../common.module.css'

const IntegratedAppSection = (props) => {
  return (
    <div className={`${text} ${container__integratedapp}`}>
      <div className={heading__integratedapp}>Seamless Integration with the apps you love</div>
      <div className={imagecontainer__integratedapp}>
        <img className={imageSize__companyLogo} src={zoho} alt="zoho"/>
        <img className={imageSize__companyLogo} src={xero} alt="xero"/>
        <img className={imageSize__companyLogo} src={docusign} alt="docusign"/>
        <img className={imageSize__companyLogo} src={tally} alt="tally"/>
        <img className={imageSize__companyLogo} src={quickbooks} alt="quickbooks"/>
      </div>
    </div>
  )
}

export default IntegratedAppSection