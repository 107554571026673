import * as React from 'react'
import {
    cardContainer, 
    imageIconLeftContainer,
    imageIconRightContainer,
    cardRightContainer,
    headingStyle,
    textStyle,
    imageCorner,
    image__paperless,
    image__fullcontainer,
} from './main.module.css'
import { text } from '../common.module.css'

export const CardWithLeftIcon = (props) => {
    const { heading, textContent, imageSrc, extraClass } = props;

  return (
    <div className={`${cardContainer} ${extraClass}`}>
        <div className={imageIconLeftContainer}>
            <img alt="icon" src={imageSrc} className={image__paperless}/>
        </div>
        <div className={cardRightContainer}>
            <div className={`${text} ${headingStyle}`}>{heading}</div>
            <div className={`${text} ${textStyle}`}>{textContent}</div>
        </div>
    </div>
  )
}

export const CardFullSize = (props) => {
    const { heading, textContent, imageSrc, extraClass } = props;
    return(
    <div className={`${cardContainer} ${extraClass}`}>
        <div>
            <div className={`${text} ${headingStyle}`}>{heading}</div>
            <div className={`${text} ${textStyle}`}>{textContent}</div>
        </div>
        <div className={imageIconRightContainer}>
            <img alt="icon" src={imageSrc} className={image__fullcontainer}/>
        </div>
    </div>
    );
}

export const CardWithSmallIcon = (props) => {
    const { heading, textContent, imageSrc, extraClass } = props;
    return(
    <div className={`${cardContainer} ${extraClass}`}>
        <div className={imageCorner}>
            <img alt="icon" src={imageSrc}/>
        </div>
        <div>
            <div className={`${text} ${headingStyle}`}>{heading}</div>
            <div className={`${text} ${textStyle}`}>{textContent}</div>
        </div>
        
    </div>
    );
}