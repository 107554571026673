import * as React from 'react'
import {
    icon__container,
    underline__text,
    underline__heading,
    imagesIconSize
} from './main.module.css';
import {text} from '../common.module.css'

const IconWithText = (props) => {
    const {image, textContent , heading} = props;
  return (
    <div className={`${text} ${icon__container}`}>
        <img src={image} alt="icon images" className={imagesIconSize}/>
        <div className={underline__heading}>{heading}</div>
        <div className={underline__text}>{textContent}</div>
    </div>
  )
}

export default IconWithText