import * as React from 'react'
import {
  complianceContainer,
  iconContainer__compliance,
  textContainer__compliance,
  heading__compliance,
  blue,
  descriptionText__compliance,
} from './main.module.css'
import {text} from '../common.module.css'

import IconWithText from './iconWithText'
import secure from '../../images/secure.svg'
import compliance from '../../images/compliance.svg'
import organised from '../../images/organised.svg'
import document from '../../images/document.svg'
import track from '../../images/track.svg'
import store from '../../images/store.svg'

const ComplianceSection = (props) => {
  return (
    <div className={complianceContainer}>
      <div className={iconContainer__compliance}>
        <IconWithText image={secure} textContent={"Data is hosted on AWS and follows ISO27001 cyber security framework"} heading={"Secure"} />
        <IconWithText image={organised} textContent={"View documents year wise and statue wise"} heading={"Organized"} />
        <IconWithText image={compliance} textContent={"Never miss any government deadline"} heading={"Compliant"} />
        <IconWithText image={track} textContent={"Track Important Regulatory dates and deadlines"} heading={"Track"} />
        <IconWithText image={document} textContent={"Manage regulatory and corporate tasks"} heading={"Manage"} />
        <IconWithText image={store} textContent={"Securely store regulatory and corporate information"} heading={"Store"} />
      </div>
      <div className={`${text} ${textContainer__compliance}`}>
        <div className={heading__compliance}>
          <div >Get your compliance paperwork done</div> 
          <div className={blue}>with confidence and get back to building your startup.</div>
        </div>       
        <div className={descriptionText__compliance}>Delego is an easy to use workspace for companies and their professionals (CA, CS, Lawyers) to communicate and  complete compliance paperwork as per Indian tax and regulatory laws. This platform minimises common errors, with unmatched delivery speeds and accuracy and also replaces the web of emails, PDFs and spreadsheets.</div>
      </div>
    </div>
  )
}

export default ComplianceSection